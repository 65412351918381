import { createContext, useEffect, useState } from "react";

const navContext=createContext()

export const Nav=({children})=>{
    const [schemeData,setSchemeData]=useState("")
    const getData=async()=>{
        try {
            let data=await fetch(`https://api.srwater.in/navbar`)
            data=await data.json()
            setSchemeData(data.data[0].name)
            console.log(data.data,"schemedata");
        } catch (error) {
            console.log(error);
        }
    }
useEffect(()=>{
    getData()
},[])

return (
    <navContext.Provider value={{schemeData}}>
            {children}
    </navContext.Provider>
)
}

export default navContext