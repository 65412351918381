import React from "react";
import "./Home.css";
import whoweare from "./images/whoweare.png";
import mission from "./images/mission.png";
import value from "./images/value.png";
import vission from "./images/vision.png";
import boll from "./images/boll.png";
import budget from "./images/budget.png";
import detox from "./images/detox.png";
import uv from "./images/uv.png";
import multi from "./images/multi.png";
import purity from "./images/purity.png";
import lux from "./images/lux.png";
import orrangefilter from "./images/orrangefilter.png";
import blackfilter from "./images/blackfilter.png";
import pinkfilter from "./images/pinkfilter.png";
import mainfilter from "./images/mainfilter.png";
// import computer from "./images/computer.png";
// import product1 from "./images/product1.png";
// import product2 from "./images/product2.png";
// import product3 from "./images/product3.png";
// import product4 from "./images/product4.png";
// import product5 from "./images/product5.png";
// import product6 from "./images/product6.png";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import srlogo from "./images/srlogo.png";
import flonixlogo from "./images/flonixlogo.png";
import "./Navbar.css";
import "./Footer.css";
import ProductPopUp from "./ProductPopUp";
import call from "./images/call.png";
import what from "./images/what.png";
import maill from "./images/maill.png";
import fb from "./images/fb.png";
import instagram from "./images/instagram.png";
import { useContext } from "react";
import navContext from "../context/navDetail";

function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [product, setProduct] = useState([]);
  const [productsData, setProductsData] = useState(null);
  const [data, setData] = useState({});
  const [nav, setNav] = useState("");
  const { schemeData } = useContext(navContext);

  const url = "https://api.srwater.in/home";
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((x) => {
        setData(x.data[0]);
        // console.log(x.data[0]);
      });
  }, []);

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    // console.log("updated", productsData);
  }, [productsData]);
  const BASE_URL = "https://api.srwater.in/home";

  // contact form
  const postPutEvent = async () => {
    const data = {
      name,
      email,
      phone,
      message,
    };
    const url = "https://api.srwater.in/contact";
    // console.log(data);
    try {
      let res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      res = await res.json();
      alert("mail sent");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  // contact form end

  const getProduct = async () => {
    try {
      // debugger;
      let data = await fetch("https://api.srwater.in/home");
      data = await data.json();
      // console.log(data.data);
      // console.log(data.data[0].products);
      setProduct(data.data[0].products);
      let products = [];
      let promises = [];

      data.data[0].products.map(async (e) => {
        promises.push(getDetails(e));
      });
      let resp = await Promise.all(promises);
      setProductsData(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const getDetails = async (id) => {
    try {
      // console.log(id);
      let data = await fetch(`https://api.srwater.in/product/${id}`);
      data = await data.json();
      // console.log(data.data, "details");
      // console.log(data.data);
      return data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  // console.log(productsData);

  return (
    <div className="fond">
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
        style={{ width: "100%" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand fw-bold " href="/">
            <img style={{ width: "100px" }} src={srlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-3">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link " href="/About">
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Product">
                  Products
                </a>
              </li>
              {/* <li className="nav-item mx-3">
                <a className="nav-link" href="/Blogs">
                  Blogs
                </a>
              </li> */}

              <li className="nav-item mx-3">
                <a className="nav-link" href="/Newsevent">
                  News & Events
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Ebroucher">
                  E-Brochure
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  style={{ fontWeight: "bolder" }}
                  className="nav-link changingcolour"
                  href="/AnnualScheme"
                >
                  {schemeData}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section
        className="service-details-page pdt-70 pdb-90   mydiv   "
        style={{ width: "100%", overflowX: "hidden" }}
      >
        <div className="container  " style={{ padding: "100px 0px 100px 0px" }}>
          <div className="row  ">
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div
                    className="col-lg-7 col-xl-7"
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  >
                    <h4
                      className="container"
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      {" "}
                      <b> WE DELIVERS THE VALUE THROUGH OUR PRODUCTS </b>
                    </h4>
                    <p
                      className="container"
                      style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        fontStyle: "normal",
                      }}
                    >
                      WE UNDERSTANDS THE WATER QUALITY YOU NEED,
                      <span style={{ color: "red" }}> SR </span> PROVIDES
                      BEYONDS EXPECTATIONS.
                    </p>
                    <p
                      className="container"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        lineHeight: "25px",
                      }}
                    >
                      Our wide range of product series is outcome of research,
                      Our team spends years in designing to manufacturing
                      process. SR Enterprise delivers you hard and smart work in
                      form of quality product. We mean the customer experience
                      and always ready for improvement.
                    </p>
                    <a className="container" href="/Contact">
                      <button
                        style={{
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "5px 10px 5px 10px",
                          color: "red",
                        }}
                      >
                        Give us call
                      </button>
                    </a>
                  </div>
                  <div className="  col-lg-5 col-xl-5  ms-auto mb-n20  mb-xxl-n22  ">
                    <div class="carousel__container">
                      <div class="carousel__item">
                        <img
                          style={{ width: "300px" }}
                          className=" container   bpump  "
                          src={orrangefilter}
                          alt=""
                          class="carousel__image"
                        />
                      </div>
                      <div class="carousel__item">
                        <img
                          style={{ width: "300px" }}
                          className=" container   bpump  "
                          src={pinkfilter}
                          alt=""
                          class="carousel__image"
                        />
                      </div>
                      <div class="carousel__item">
                        <img
                          style={{ width: "300px" }}
                          className=" container   bpump  "
                          src={blackfilter}
                          alt=""
                          class="carousel__image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="service-details-page pdt-70 pdb-90 my-5     "
        style={{ width: "100%" }}
      >
        <div className="container   ">
          <div className="row  ">
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="  col-lg-5 col-xl-5  ms-auto mb-n20  mb-xxl-n22  ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "400px" }}
                        className=" container   "
                        src={whoweare}
                        alt=""
                      />
                    </div>
                  </div>
                  {data && (
                    <div
                      className="col-lg-7 col-xl-7"
                      style={{
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      <h1 className="hed1">
                        {" "}
                        <b>{data?.top_heading} </b>{" "}
                      </h1>
                      <p style={{ fontSize: "16px", fontWeight: "300" }}>
                        {data?.top_content}
                      </p>
                      <button className="btn btn1">
                        <a href="/About">Read More</a>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details-page pdt-70 pdb-90 ">
        <div className="container ">
          <div className="row ">
            <div className="col-md-12">
              <div>
                <div className="row " style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <div className="single-box">
                      <div className="img-text">
                        <img
                          className="vert-move mx-3"
                          src={mission}
                          alt=""
                          style={{ width: "90px" }}
                        />
                        <p
                          className="mx-3"
                          style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            marginBottom: "0",
                          }}
                        >
                          Mission
                        </p>

                        <p
                          style={{
                            textAlign: "justify",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          {data?.mission}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-box">
                      <img
                        className="vert-move mx-2"
                        src={value}
                        alt=""
                        style={{ width: "76px" }}
                      />
                      <div className="img-text">
                        <h5
                          className="mx-2"
                          style={{ fontSize: "40px", fontWeight: "600" }}
                        >
                          Values
                        </h5>

                        <p
                          className=""
                          style={{
                            textAlign: "justify",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          {data?.values}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-box">
                      <img
                        className="vert-move mb-3 mx-2 "
                        src={vission}
                        alt=""
                        style={{ width: "120px" }}
                      />
                      <div className="img-text">
                        <h5
                          className="mt-3 mx-2"
                          style={{ fontSize: "40px", fontWeight: "600" }}
                        >
                          Vision
                        </h5>

                        <p
                          className=""
                          style={{
                            textAlign: "justify",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          {data?.vision}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="service-details-page pdt-70 pdb-90  my-5   "
        style={{ overflowX: "hidden" }}
      >
        <div className="container  ">
          <h1 className="text-center">
            {" "}
            <b> EXPERIENCE PURITY IN EVERY DROP </b>
          </h1>
          <h4 className="text-center">What's Inside?</h4>
          <div className="row  ">
            <div className="col-xl-12 col-lg-12  ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div
                    className="  col-lg-4 col-xl-4     "
                    data-aos="slide-right"
                    data-aos-duration="2000"
                  >
                    <div>
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={multi} alt="" />
                        &nbsp; <b> Multi layer protection</b>
                      </h5>
                      <p style={{ paddingLeft: "42px" }}>
                        Water filtration Done In multiple stages & Protect your
                        Health.
                      </p>
                    </div>
                    <div className="my-5">
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={purity} alt="" />
                        &nbsp; <b> Purity For Health </b>
                      </h5>
                      <p style={{ paddingLeft: "40px" }}>
                        A magical potion to lose weight and improve health of
                        Hair and Skin.
                      </p>
                    </div>
                    <div className="my-5">
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={lux} alt="" />
                        &nbsp; <b> Luxurious Appearance</b>
                      </h5>
                      <p style={{ paddingLeft: "40px" }}>
                        Give Rich Experience to your kitchen with Luxurious
                        Purifier.
                      </p>
                    </div>
                  </div>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <img className=" container      " src={mainfilter} alt="" />
                  </div>
                  <div
                    className="  col-lg-4 col-xl-4 mt-5"
                    data-aos="slide-left"
                    data-aos-duration="2000"
                  >
                    <div>
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={budget} alt="" /> &nbsp;{" "}
                        <b>Budget Friendly </b>
                      </h5>
                      <p style={{ paddingLeft: "42px" }}>
                        Cost Effective and Pocket Friendly with Fabulous
                        Quality.
                      </p>
                    </div>
                    <div className="my-5">
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={detox} alt="" /> &nbsp;{" "}
                        <b> Key of Detox your body </b>
                      </h5>
                      <p style={{ paddingLeft: "42px" }}>
                        Now we provide key for your body to Detox it for healthy
                        life.
                      </p>
                    </div>
                    <div className="my-5">
                      <h5 style={{ display: "flex", alignItems: "center" }}>
                        <img width={35} src={uv} alt="" /> &nbsp;{" "}
                        <b>UF and UV Technology </b>
                      </h5>
                      <p style={{ paddingLeft: "40px" }}>
                        Ultra filtration and UV give Wing to your daily need
                        water Toward Healthy Life.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details-page pdt-70 pdb-90  my-5   ">
        <div className="container  ">
          <h1 className="text-center my-4">
            {" "}
            <b> CHOOSE YOUR WATER PURIFIER </b>
          </h1>
          <h4 className="text-center my-4"> Find the right product for you </h4>
          <div className="row container">
            <div className="middle-boxx container">
              {productsData?.length &&
                productsData
                  ?.filter((e) => e !== null)
                  .map((e, i) => (
                    <Box key={e?._id}>
                      {/* <img
                      
                        className="img container"
                        style={{  textAlign: "center",width:"250px" }}
                        src={"https://api.srwater.in/product/" + e?.image[0]}
                      />
                       */}
                      <div style={{ textAlign: "center", cursor: "pointer" }}>
                        {e && (
                          <ProductPopUp
                            mainImage={e?.image}
                            spec={e?.specification}
                            desc={e?.description}
                            name={e?.name}
                            style={{ alignItems: "center" }}
                          />
                        )}
                      </div>
                    </Box>
                  ))}
            </div>

            {/* <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text  ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22 my-3  ">
                    {newproduct?.length &&
                      newproduct.map((e) => {
                        console.log(e);
                        return (
                          <div className="box-ponks">
                            <img
                              style={{
                                width: "250px",
                                background: "whitesmoke",
                              }}
                              className=" container      "
                              src={
                                "http://192.168.1.12:8080/product/" + e.image
                              }
                              alt=""
                            />
                            <p className="text-center py-1">{e.name}</p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "250px", background: "whitesmoke" }}
                        className=" container      "
                        src={product2}
                        alt=""
                      />
                      <p className="text-center py-1">
                        {data?.section4?.title2}
                      </p>
                    </div>
                  </div>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "250px", background: "whitesmoke" }}
                        className=" container      "
                        src={product3}
                        alt=""
                      />
                      <p className="text-center py-1">
                        {data?.section4?.title3}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-12 col-lg-12 my-2 ">
              <div className="service-detail-text  ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "250px", background: "whitesmoke" }}
                        className=" container      "
                        src={product4}
                        alt=""
                      />
                      <p className="text-center py-1">
                        {data?.section4?.title4}
                      </p>
                    </div>
                  </div>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "250px", background: "whitesmoke" }}
                        className=" container      "
                        src={product5}
                        alt=""
                      />
                      <p className="text-center py-1">
                        {data?.section4?.title5}
                      </p>
                    </div>
                  </div>
                  <div className="  col-lg-4 col-xl-4  ms-auto mb-n20  mb-xxl-n22   ">
                    <div className="box-ponks">
                      <img
                        style={{ width: "250px", background: "whitesmoke" }}
                        className=" container      "
                        src={product6}
                        alt=""
                      />
                      <p className="text-center py-1">
                        {data?.section4?.title6}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="text-center pt-4">
              <button className="btn btn1">
                <a href="/Product">All product</a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details-page pdt-70 pdb-90    ">
        <div className="container  ">
          <div className="row  ">
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text  ">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="  col-lg-6 col-xl-6  ms-auto mb-n20  mb-xxl-n22   ">
                    <img
                      className=" container"
                      src={BASE_URL + "/" + data?.bottom_image}
                      alt=""
                    />
                  </div>
                  <div className="  col-lg-6 col-xl-6  my-5   ">
                    <h1>
                      <b> {data?.bottom_heading} </b>
                    </h1>
                    <p className="my-3">{data?.bottom_content}</p>
                    <button className="btn btn1">
                      <a
                        href="/Contact"
                      
                      >
                        Call Now
                        
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5" id="contact">
        <div className="container">
          <h1 className="text-center ">
            {" "}
            <b> Send Us A Message </b>{" "}
          </h1>
          <div className="row" style={{ alignItems: "center" }}>
            <img className="boll1" src={boll} alt="" />
            <div className="col-lg-5">
              <div
                className="container"
                style={{
                  background: "black",
                  color: "white",
                  textAlign: "justify",
                  padding: "100px 20px 100px 20px",
                }}
              >
                <h3 className="container" style={{ whiteSpace: "pre-line" }}>
                  {data?.contact_heading}
                </h3>
                <p className="container">{data?.contact_content}</p>
              </div>
            </div>
            <img className="boll2" src={boll} alt="" />

            <div className="col-lg-6 my-5">
              <form>
                <div className="mb-3">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    style={{ border: "1px solid black" }}
                    type="Name"
                    placeholder="Full name*"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>
                <div className="mb-3">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ border: "1px solid black" }}
                    type="Name"
                    placeholder="Email*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>

                <div className="mb-3">
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    style={{ border: "1px solid black" }}
                    type="tel"
                    placeholder="Phone*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    style={{ border: "1px solid black" }}
                    type="text area"
                    placeholder="Message*"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>
                <button
                 className="btn btn1"
                  onClick={postPutEvent}
                  type="submit"
                  id="contact-submit"
                  data-submit="...Sending"
                >
                 Submit
                </button>

                
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer style={{ marginBottom: "-1000px" }}>
        <svg viewBox="0 0 120 28">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="1"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                result="goo"
              />
              <xfeBlend in="SourceGraphic" in2="goo" />
            </filter>
            <path
              id="wave"
              d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
            />
          </defs>

          <use id="wave3" class="wave" href="#wave" x="0" y="-2"></use>
          <use id="wave2" class="wave" href="#wave" x="0" y="0"></use>

          <g class="gooeff" filter="url(#goo)">
            <use id="wave1" class="wave" href="#wave" x="0" y="1" />
          </g>

          <path
            id="wave1"
            class="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </svg>

        <section className="wavesh">
          <div className="container text-center   ">
            <div className="row ">
              <div className="col-lg-3 col-xl-3   ">
                <a href="/">
                  <img
                    style={{ width: "150px", alignItems: "left" }}
                    src={flonixlogo}
                    className=" d-inline-block mx-auto "
                  ></img>
                </a>
              </div>

              <div
                className=" col-lg-2 col-xl-2  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/"
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/About"
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Products"
                  >
                    Products
                  </a>
                </p>
                {/* <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Blogs"
                   
                  >
                    Blogs
                  </a>
                </p> */}
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Newsevent"
                  >
                    News & Events
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Contact"
                  >
                    Contact Us
                  </a>
                </p>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <p>
                    <i class="fa-solid fa-location-dot fa-lg mr-3 "></i>
                  </p>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://maps.app.goo.gl/Qe6AeaLbJ8QXFPXv6"
                    target="_blank"
                    rel="nopper"
                    className="mx-3"
                  >
                    Godown No. 3-4-5, Prince Estate, Behind Shyam Sangini
                    Market, Saniya - Hemad Road, Saroli, Surat - 395010
                  </a>
                </div>
                <p className="mt-3">
                  <i class="fa-solid fa-phone fa-lg mr-3 "></i>

                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="tel:9173664364"
                    className="mx-2"
                  >
                    {" "}
                    +91 91736 64364
                  </a>
                </p>
                <p>
                  <i className="fas fa-envelope fa-lg mr-3 "></i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="mailto:srwater031987@gmail.com"
                    target="_blank"
                    rel="nopper"
                    className="mx-2"
                  >
                    {" "}
                    srwater031987@gmail.com
                  </a>
                </p>
              </div>
              <div
                className=" col-lg-3 col-xl-3  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Business Hours</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <h6>Monday-Saturday: 9AM to 7PM</h6>
                </p>
                <p>
                  <h6>Sunday: Closed</h6>
                </p>
                <p className="my-2" style={{ display: "flex" }}>
                  <a
                    className=""
                    href={"tel:9173664364"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      width={35}
                      src={call}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    href={"https://wa.me/9173664364"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      width={35}
                      src={what}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"mailto:srwater031987@gmail.com"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={maill}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={
                      "https://www.facebook.com/srwatergroup123?sfnsn=wiwspwa&mibextid=RUbZ1f"
                    }
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={fb}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={
                      "https://www.instagram.com/srwatergroup?igsh=bTdxMGthMWllajY0"
                    }
                    target="_blan"
                    rel="nopper"
                  >
                    <img
                      src={instagram}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3 wavesh">
          <p>Copyright © 2023. SR ENTERPRISE | All Rights Reserved.</p>
          DESIGN & DEVELOPED BY:{" "}
          <a
            target="_blank"
            rel="nopper"
            style={{ textDecoration: "none" }}
            href="https://teamflourish.co/"
          >
            FLOURISH CREATIONS PVT LTD{" "}
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
