import React from "react";
import "./Cardproduct.css";
import call from "./images/call.png";
import what from "./images/what.png";
import maill from "./images/maill.png";
import fb from "./images/fb.png";
import orcode from "./images/orcode.png";
import instagram from "./images/instagram.png";
import flonixlogo from "./images/flonixlogo.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import share from "./images/share.png";

function Cardhome({ detail }) {
  // const [home, setHome] = useState({});

  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  // let { names } = useParams();
  const url = `https://api.srwater.in`;
  // useEffect(() => {}, []);
  const [product, setProduct] = useState([]);
  const [productsData, setProductsData] = useState();
  const [whatsapp, setWhatsapp] = useState("");
  const [view, setView] = useState(0);
  const { names } = useParams();
  const url1 = `https://api.srwater.in/card/contact/${detail?.name}`;
  const [shareLink, setShateLink] = useState(false);
  const [id, setId] = useState("");
  const [ip, setIp] = useState("");

  const handleShare = async () => {
    try {
      if (shareLink) {
        await navigator.share({
          title: `Share by ${detail?.name}`,
          text: `Hi, I'm ${detail?.name}, Check my digital business card`,
          url: ` https://srwater.in/card/${detail?.generated_name}`,
        });
      } else {
        alert("Your Browser Doesn't support Sharing");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProduct = async () => {
    try {
      // debugger;
      // console.log(names);
      let data = await fetch(`https://api.srwater.in/card/${names}`);
      data = await data.json();
      // console.log(data.data);
      // console.log(data.data[0].products);
      setProduct(data.data[0].products);
      let products = [];
      let promises = [];

      data.data[0].products.map(async (e) => {
        promises.push(getDetails(e));
      });
      let resp = await Promise.all(promises);
      setProductsData(resp);
    } catch (error) {
      console.log(error);
    }
  };

  // const cheeckProduct=async(id)=>{
  //   try {
  //     let data=await fetch(`http://192.168.1.22:8080/product/${id}`)
  //     data=await data.json()
  //     if(data.data){
  //       return true
  //     }else{
  //       return false
  //     }
  //   } catch (error) {

  //   }
  // }

  const getDetails = async (id) => {
    try {
      // console.log(id);
      let data = await fetch(`https://api.srwater.in/product/${id}`);
      data = await data.json();
      // console.log(data.data, "details");
      // console.log(data.data);
      return data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getData = async () => {
    try {
      let data = await fetch(`https://api.srwater.in/card/${names}`);
      data = await data.json();
      //  console.log(data.data[0].users.length);
      setId(data.data[0]._id);
      // setView(data.data[0].users?.length)
      getIp().then((res) => addIp(res));
    } catch (error) {
      console.log(error);
    }
  };

  const getView = async () => {
    try {
      let data = await fetch(`https://api.srwater.in/card/${names}`);
      data = await data.json();
      //  console.log(data.data[0].users.length);

      setView(data.data[0].users?.length);
    } catch (error) {
      console.log(error);
    }
  };
  const addIp = async (ip) => {
    // debugger
    //  console.log(id,"id");

    if (!id) {
      console.error("Detail is not available or does not have _id property");
      return;
    }
    try {
      let data = await fetch(`https://api.srwater.in/view/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ip: ip, name: id }),
      });
      data = await data.json();
      await getView();
      //  console.log(data);
      // await getData()
    } catch (error) {
      console.log(error);
    }
  };

  const downloadContact = async () => {
    try {
      // debugger
      let data = await axios.get(`https://api.srwater.in/downloadContact`);

      // console.log(data.data);
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getIp = async () => {
    // debugger
    try {
      let ipData = await fetch("https://ipapi.co/json");
      ipData = await ipData.json();
      // console.log(ipData.ip);
      setIp(ipData.ip);
      return ipData.ip;
      // let data=await fetch(`http://192.168.1.15:8000/view/add`)
    } catch (error) {
      console.log(error);
    }
  };

  // const [product, setProduct] = useState({});
  // const getItem=async()=>{
  //   try {
  //     let data=await fetch("http://192.168.1.12:8080/product/")
  //         data=await data.json()
  //       //   console.log(data.data);
  //         setProduct(data.data)
  //   } catch (error) {
  //     console.log(error);
  //   }
  //  }
  //  useEffect(()=>{
  //    getItem()

  //  },[])

  const handleChanger = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const sendMail = async () => {
    // debugger
    if (!contact.name || !contact.email || !contact.phone || !contact.message) {
      alert("Pleass Fill All Fields");
    } else {
      try {
        let data = await fetch(`${url1}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contact),
        });
        data = await data.json();
        // console.log(data);
        alert("mail sent");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getData();
    getProduct();
  }, [detail]);

  useEffect(() => {
    getProduct();
    getData();

    // console.log("product", productsData);

    if (navigator.share) {
      setShateLink(true);
    }
  }, [setProductsData]);
  return (
    <>
      <section className=" container" id="Cardhome">
        <div
          className="  row justify-content-center "
          style={{ borderRadius: "20px" }}
        >
          <div className="col-md-6 col-sm-12 content">
            <div className="pt-3" style={{ textAlign: "end", color: "white" }}>
              <i class="fa-solid fa-eye"></i> <span>{view}</span>
            </div>
            <div className="header">
              <a href="/">
                <img className="logo " src={flonixlogo} alt="" />
              </a>
              <img
                className="avatar "
                style={{ width: "150px" }}
                src={`${url}/profile/${detail?.image}`}
                alt=""
              />
              <div className="info">
                <h3
                  style={{
                    color: "white",
                    fontWeight: "bolder",
                    fontSize: "1.75rem",
                  }}
                >
                  {detail?.prefix} {detail?.name}
                </h3>
                <p
                  style={{ color: "white", fontSize: "16px" }}
                  className="mb-0"
                >
                  {detail?.designation}
                </p>
                <h3
                  className="pt-1"
                  style={{
                    color: "white",
                    fontWeight: "bolder",
                    fontSize: "16px",
                  }}
                >
                  {detail?.company_name}
                </h3>
              </div>
            </div>
            <div className="social">
              <div className="icon">
                <a
                  href={"tel:" + detail?.mobile}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="icon-phone">
                    {" "}
                    <img width={35} src={call} alt="" />
                  </span>
                </a>
              </div>
              <div className="icon">
                <a
                  href={"https://wa.me/" + detail?.whatsapp}
                  target="_blan"
                  rel="nopper"
                >
                  <span className="icon-phone">
                    {" "}
                    <img width={35} src={what} alt="" />{" "}
                  </span>
                </a>
              </div>
              <div className="icon">
                <a href={"mailto:" + detail?.email} target="_blan" rel="nopper">
                  <span className="icon-phone">
                    <img src={maill} width={35} alt="" />
                  </span>
                </a>
              </div>
              <div className="icon">
                <a href={detail?.facebook_link} target="_blan" rel="nopper">
                  <span className="icon-phone">
                    <img src={fb} width={35} alt="" />
                  </span>
                </a>
              </div>
              <div className="icon">
                <a href={detail?.insta_link} target="_blan" rel="nopper">
                  <span className="icon-phone">
                    <img src={instagram} width={35} alt="" />
                  </span>
                </a>
              </div>
              {shareLink && (
                <div className="icon" style={{ cursor: "pointer" }}>
                  <span className="icon-phone">
                    <img
                      src={share}
                      style={{ marginTop: "2px" }}
                      width={30}
                      alt="share"
                      onClick={handleShare}
                    />
                    {/* <ShareButton>share</ShareButton> */}
                  </span>
                </div>
              )}
            </div>
            <div className="call-to-action">
              <a
                className="cta"
                href={`https://api.srwater.in/downloadContact?name=${detail?.name}&number=${detail?.mobile}`}
              >
                <span className="icon-phone">
                  <i class="fa-solid fa-address-book fa-2xl px-2"></i>
                  Add to Contact Book
                </span>
              </a>
              <div className="share">
                <div className="form-group m-0">
                  <input
                    type="text"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    className="form-control"
                    placeholder="Enter Phone Number"
                  />
                </div>
                <button
                  style={{ color: "white", border: "1px solid white" }}
                  type="number"
                  onClick={() =>
                    (window.location.href = `https://api.whatsapp.com/send/?phone=+91${whatsapp}&text=Hi, I'm ${detail?.name}, Check my digital business card https://srwater.in/card/${detail?.generated_name} `)
                  }
                  className="mx-1   btn-theme"
                  fdprocessedid="072x5b"
                >
                  <span className="">
                    <i class="fa-brands fa-whatsapp fa-xl mx-1"></i>
                    Share
                  </span>
                </button>
              </div>
            </div>
            <div className="details">
              <div className="detail">
                <a href={detail?.address_link} target="_blan" rel="nopper">
                  <h6
                    className="d-flex"
                    style={{ alignItems: "center", gap: "8px" }}
                  >
                    <span>
                      <i
                        style={{ color: "black" }}
                        class="fa-solid fa-location-dot fa-lg "
                      ></i>
                    </span>
                    {detail?.address}
                  </h6>
                </a>
              </div>
              <div className="detail">
                <a href={"mailto:" + detail?.email} target="_blan" rel="nopper">
                  <h6
                    className="d-flex"
                    style={{ alignItems: "center", gap: "8px" }}
                  >
                    {" "}
                    <span>
                      <i
                        style={{ color: "black" }}
                        class="fa-solid fa-envelope "
                      ></i>
                    </span>
                    {detail?.email}
                  </h6>
                </a>
              </div>
              <div className="detail">
                <a href={detail?.company_link} target="_blan" rel="nopper">
                  <h6
                    className="d-flex"
                    style={{ alignItems: "center", gap: "8px" }}
                  >
                    {" "}
                    <span>
                      <i
                        style={{ color: "black" }}
                        class="fa-solid fa-house "
                      ></i>
                    </span>
                    {detail?.company_link}
                  </h6>
                </a>
              </div>
              <div className="detail">
                <a href={"tel:" + detail?.mobile}>
                  <h6
                    className="d-flex"
                    style={{ alignItems: "center", gap: "8px" }}
                  >
                    <span className="">
                      <i
                        style={{ color: "black" }}
                        class="fa-solid fa-phone "
                      ></i>
                    </span>

                    {detail?.mobile}
                  </h6>
                </a>
              </div>
            </div>
            <div className="social partial">
              <div className="icon squre" style={{ cursor: "pointer",boxShadow:"2px 2px 2px 2px  blue" }}>
                <a target="_blan" href="https://www.facebook.com/srwatergroup123?sfnsn=wiwspwa&mibextid=RUbZ1f" rel="nopper">
                  <span className="icon-phone">
                    <i class="fa-brands fa-square-facebook"></i>
                  </span>
                </a>
              </div>
              <div className="icon squre" style={{ cursor: "pointer",boxShadow:"2px 2px 2px 2px #e1306c " }}>
                <a target="_blan" href="https://www.instagram.com/srwatergroup?igsh=bTdxMGthMWllajY0" rel="nopper">
                  <span className="icon-phone">
                    <i
                      class="fa-brands fa-square-instagram"
                      style={{
                        background:
                          "linear-gradient(to right,#405de6,#5851db,#833ab4,#c13584,#e1306c,#fd1d1d)",
                        webkitBackgroundClip: "text",
                        webkitTextFillColor: "transparent",
                      }}
                    ></i>
                  </span>
                </a>
              </div>
              <div className="icon squre" style={{ cursor: "pointer" ,boxShadow:"2px 2px 2px 2px red" }}>
                <a href="https://www.youtube.com/channel/UCvkuFsFDTMNNE2oHqGycpDA" target="_blan" rel="nopper">
                  <span className="icon-phone">
                    <i
                      class="fa-brands fa-youtube"
                      style={{ color: "red" }}
                    ></i>
                  </span>
                </a>
              </div>
              
            </div>
            <div id="Cardproduct">
              <h1
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  // marginLeft: "20px",
                }}
              >
                Products
              </h1>
              {productsData?.length &&
                productsData?.map((e) => {
                  return (
                    e && (
                      <div className="row products ">
                        <div className="col-12">
                          <div className="product-card hoverable ">
                            <img
                              className="p-image rest "
                              src={`https://api.srwater.in/product/${e?.image[0]}`}
                              alt="img error"
                            />
                            <div className="product-info">
                              <h6
                                className="mb-0"
                                style={{
                                  paddingTop: "4px",
                                  fontWeight: "bolder",
                                }}
                              >
                                {" "}
                                {e?.name}
                              </h6>
                              <a
                                href={`https://api.whatsapp.com/send/?phone=+91${detail.whatsapp}&text=Hello ${detail.name},I want to get details for your product ${e.name}`}
                                classButton="btn btn-sm btn-primary btn-theme"
                                style={{
                                  border: "1px solid black",
                                  padding: "1px 5px",
                                  background: "silver",
                                  borderRadius: "5px",
                                }}
                              >
                                INQUIRY
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <br />
            
            <div
              // style={{
              //   marginTop: "35px",
              // }}
              class="payment-method"
              id="Cardpayment"
            >
              <div className="payment-detail">
                <h3 className="" style={{ fontWeight: "bold" }}>
                  Payment
                </h3>
                <div class="data">
                  <h6>
                    <span>Bank Name</span>
                    <span>: {detail?.bank_name}</span>
                  </h6>
                  <h6>
                    <span>Account Holder Name</span>
                    <span>: {detail?.account_holder}</span>
                  </h6>
                  <h6>
                    <span>Account Number</span>
                    <span>: {detail?.account_number}</span>
                  </h6>
                  <h6>
                    <span>Account Type</span>
                    <span>: {detail?.account_type}</span>
                  </h6>
                  <h6>
                    <span>IFSC Code</span>
                    <span>: {detail?.ifsc}</span>
                  </h6>
                  {/* <h6>
                    <span>GST Company</span>
                    <span>: {detail?.gst_name}</span>
                  </h6>
                  <h6>
                    <span>GST Billing Address</span>
                    <span>: {detail?.gst_address}</span>
                  </h6>
                  <h6>
                    <span>GST Number</span>
                    <span>: {detail?.gst_number}</span>
                  </h6> */}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "25px" }}>
              {/* <img className="container" src={orcode} alt="img error" style={{width:"400px"}}/> */}
              {detail?.qr_code?.map((e) => {
                return (
                  <>
                    <img
                      className="container"
                      src={`https://api.srwater.in/payment/${e}`}
                      alt="img error"
                      style={{ width: "400px" }}
                    />
                    <br />
                  </>
                );
              })}
            </div>
            <div class="payment-method mt-0"  id="Cardpayment">
              <div className="payment-detail">
                <h3 className="" style={{ fontWeight: "bold" }}>
                  GST Detail
                </h3>
                <div class="data">
                  <h6>
                    <span>GST Company</span>
                    <span>: {detail?.gst_name}</span>
                  </h6>
                  <h6>
                    <span>GST Billing Address</span>
                    <span>: {detail?.gst_address}</span>
                  </h6>
                  <h6>
                    <span>GST Number</span>
                    <span>: {detail?.gst_number}</span>
                  </h6>
                </div>
              </div>
            </div>

            <div
              className="   container   "
              id="Cardinquiry"
              style={{
                background: "#FAFAFA",
                borderRadius: "20px",
              }}
            >
              <div id="inq" className="form-wrap ">
                <h3 className="text-left pt-3" style={{ fontWeight: "bold" }}>
                  Inquiry
                </h3>
                <hr />

                <div className="row">
                  <div className="col-md-6 my-1">
                    <div className="form-group">
                      <label
                        id="name-label "
                        htmlFor="name"
                        style={{ fontWeight: "bold" }}
                      >
                        Enter Name
                      </label>
                      <input
                        onChange={(e) => handleChanger(e)}
                        value={contact.name}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 my-1">
                    <div className="form-group">
                      <label
                        id="name-label"
                        htmlFor="name"
                        style={{ fontWeight: "bold" }}
                      >
                        Enter Number
                      </label>
                      <input
                        onChange={(e) => handleChanger(e)}
                        value={contact.phone}
                        type="tel"
                        name="phone"
                        id="name"
                        placeholder="Phone Number"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 my-1">
                    <div className="form-group">
                      <label
                        id="email-label"
                        htmlFor="email"
                        style={{ fontWeight: "bold" }}
                      >
                        Enter Email
                      </label>
                      <input
                        onChange={(e) => handleChanger(e)}
                        value={contact.email}
                        type="text"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 my-1">
                    <div className="form-group">
                      <label
                        style={{ paddingRight: "0px", fontWeight: "bold" }}
                      >
                        Enter Message
                      </label>
                      <textarea
                        onChange={(e) => handleChanger(e)}
                        value={contact.message}
                        id="comments"
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="container my-3 text-center">
                  <button className="cardbuts btnn btn1" onClick={sendMail}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div class="bottom-bar">
              <div class="navigation">
                <div class="tab">
                  <a href="#Cardhome">
                    <h5 class="zoom">
                      <span class="icon-home">
                        <i class="fa-solid fa-house "></i>
                      </span>
                    </h5>
                    Home
                  </a>
                </div>
                <div class="tab">
                  <a href="#Cardproduct">
                    <h5 class="zoom">
                      <span class="icon-home">
                        <i class="fa-solid fa-bag-shopping"></i>
                      </span>
                    </h5>
                    Product
                  </a>
                </div>

                <div class="tab">
                  <a href="#Cardpayment">
                    <h5 class="zoom">
                      <span class="icon-home">
                        <i class="fa-solid fa-indian-rupee-sign"></i>
                      </span>
                    </h5>
                    Payment
                  </a>
                </div>

                <div class="tab">
                  <a
                    href="https://www.google.com/search?q=srwater&sca_esv=3fe0b148ff4f6c90&rlz=1CDGOYI_enIN1046IN1046&hl=en-GB&biw=393&bih=665&sxsrf=ACQVn09C9e5Bz1KlHyUQO0IHZbLW4TZFfg%3A1706699756252&ei=7Cu6Ze77DtSjseMPuI2G0AY&oq=srwater&gs_lp=EhNtb2JpbGUtZ3dzLXdpei1zZXJwIgdzcndhdGVyMgQQIxgnMgQQIxgnMgoQABiABBjJAxgKMgsQABiABBiKBRiSAzIHEAAYgAQYCjIKEAAYgAQYChixAzINEAAYgAQYChixAxiDATIKEAAYgAQYChixA0iZHVC3EViXF3AAeACQAQCYAYYEoAHSDaoBCzAuMS4zLjEuMC4xuAEDyAEA-AEBwgIKECMYsAIYsAMYJ8ICCxAAGIAEGKIEGLAD4gMEGAEgQYgGAZAGBQ&sclient=mobile-gws-wiz-serp#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D&trex=m_t:lcl_akp,rc_f:rln,rc_ludocids:14311971909344002864,ru_gwp:0%252C7,ru_lqi:CgdzcndhdGVySJ_E_LbeuoCACFoNEAAYACIHc3J3YXRlcpIBGndhdGVyX3B1cmlmaWNhdGlvbl9jb21wYW55,trex_id:VpaZWd"
                    target="_blan"
                    rel="nopper"
                  >
                    <h5 class="zoom">
                      <span class="icon-home">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </h5>
                    Rating
                  </a>
                </div>
                <div class="tab">
                  <a href="#Cardinquiry">
                    <h5 class="zoom">
                      <span class="icon-home">
                        <i class="fa-solid fa-message"></i>
                      </span>
                    </h5>
                    Inquiry
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cardhome;
