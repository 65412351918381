import React, { useEffect, useState } from "react";
import "./Product.css";
import srlogo from "./images/srlogo.png";
import flonixlogo from "./images/flonixlogo.png";
import "./Navbar.css";
import "./Footer.css";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import ProductPopUp from "./ProductPopUp";
import call from "./images/call.png"
import what from "./images/what.png"
import maill from "./images/maill.png"
import fb from "./images/fb.png"
import instagram from "./images/instagram.png"

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import navContext from "../context/navDetail";
import { useContext } from "react";

function Product() {
  const BASE_URL = "https://api.srwater.in/product";
  const { onOpen } = useDisclosure();
  let [product, setProduct] = useState([]);
  let [category, setCategory] = useState([]);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(0);
  const [categoryPage, setCategoryPage] = useState(1);
  const [board, setBoard] = useState("ALL PRODUCTS");
  const [flag, setFlag] = useState(false);
  const {schemeData}=useContext(navContext)



  const filterItems = async (id) => {
    try {
      setFlag(true);

      let data = await fetch(
        `https://api.srwater.in/category/${id}?page=${categoryPage}`
      );
      data = await data.json();
      // console.log(data);
      setProduct(data.data[0].products);
      setBoard(data.data[0].name.toUpperCase());
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      let data = await fetch("https://api.srwater.in/category");
      data = await data.json();
      // console.log(data.data);
      setCategory(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getItem = async () => {
    try {
      let data = await fetch("https://api.srwater.in/product");
      data = await data.json();
      // console.log(data.data);

      setPageSize(data.data.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getproduct = async () => {
    const res = await fetch(`https://api.srwater.in/product?page=${page}`);
    const data = await res.json();
    // console.log(data.data);
    setProduct(data.data);
  };
  useEffect(() => {
    getItem();
    getCategory();
    getproduct();
    setBoard("ALL PRODUCTS");
    console.log(product);
    // console.log(product);
  }, [page]);

  return (
    <div className="fond">
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
        style={{ width: "100%" }}
      >
        <div
          className="container-fluid"
          style={{ justifyContent: "space-around" }}
        >
          <a className="navbar-brand fw-bold" href="/">
            <img style={{ width: "100px" }} src={srlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link mx-3 " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/About">
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link active" href="/Product">
                  Products
                </a>
              </li>
              {/* <li className="nav-item mx-3">
                <a className="nav-link " href="/Blogs">
                  Blogs
                </a>
              </li> */}

              <li className="nav-item mx-3">
                <a className="nav-link" href="/Newsevent">
                  News & Events
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Ebroucher">
                  E-Brochure
                </a>
              </li>
              <li className="nav-item mx-3">
              <a style={{fontWeight:"bolder"}} className="nav-link changingcolour" href="/AnnualScheme">
                {schemeData}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="aboutbanner" id="top">
        <div className="banner-text">
          <h1 className="aboutus">Products</h1>
        </div>
      </section>
      <Box  style={{overflowX:"hidden"}}>
        <Heading className="mt-4" textAlign={"center"}>
          {board}
        </Heading>
        <Box display={"flex"} justifyContent={"space-around"}>
          <div className="sidebar">
            <a className="hovred" href="/Product">
              All Products
            </a>
            {category?.length &&
              category.map((e) => {
                return (
                  <>
                    <h6
                      id="tops"
                      onClick={() => filterItems(e._id)}
                      style={{
                        // paddingLeft: "20px",
                        paddingTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {e.name}
                    </h6>
                  </>
                );
              })}
          </div>
          <div className="space-box">
            {product?.length>0&&
              product.map((e) => {
                return (
                  <Box
                    onClick={onOpen}
                    style={{ textAlign: "center", borderRadius: "10px" }}
                  >
                  
                    <ProductPopUp
                      mainImage={e.image}
                      spec={e.specification}
                      desc={e.description}
                      name={e.name}
                      price={e.price}
                    />
                  </Box>
                );
              })}
          </div>
        </Box>
        {flag ? (
          <div className="justify-content-center d-flex">
            <a href="#top">
              <Button 
               clasName="mx-5"
                variant={"outline"}
                border="1px solid #161616"
                isDisabled={categoryPage == 1}
                onClick={() => setCategoryPage(categoryPage - 1)}
              >
                <BsArrowLeft />
              </Button>
            </a>
            <Button className="mx-2">{categoryPage}</Button>
            <a href="#top">
              <Button
              
                variant={"outline"}
                border="1px solid #161616"
                isDisabled={categoryPage > Math.floor(product.length / 12)}
                onClick={() => setCategoryPage(categoryPage + 1)}
              >
                <BsArrowRight />
              </Button>
            </a>
          </div>
        ) : (
          <div className="justify-content-center d-flex">
            <a href="#top">
              <Button
              
                variant={"outline"}
                border="1px solid #161616"
                isDisabled={page == 1}
                onClick={() => setPage(page - 1)}
              >
                <BsArrowLeft />
              </Button>
            </a>
            <Button className="mx-2">{page}</Button>
            <a href="#top">
              <Button
              
                variant={"outline"}
                border="1px solid #161616"
                isDisabled={page > Math.floor(pageSize / 12)}
                onClick={() => setPage(page + 1)}
              >
                <BsArrowRight />
              </Button>
            </a>
          </div>
        )}
      </Box>

      <footer style={{ marginBottom: "-1000px" }}>
        <svg viewBox="0 0 120 28">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="1"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                result="goo"
              />
              <xfeBlend in="SourceGraphic" in2="goo" />
            </filter>
            <path
              id="wave"
              d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
            />
          </defs>

          <use id="wave3" class="wave" href="#wave" x="0" y="-2"></use>
          <use id="wave2" class="wave" href="#wave" x="0" y="0"></use>

          <g class="gooeff" filter="url(#goo)">
            <use id="wave1" class="wave" href="#wave" x="0" y="1" />
          </g>

          <path
            id="wave1"
            class="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </svg>

        <section className="wavesh">
          <div className="container text-center   ">
            <div className="row ">
              <div className="col-lg-3 col-xl-3   ">
                <a href="/">
                <img
                  style={{width:"150px",alignItems:"left"}}
                  src={flonixlogo}
                  className=" d-inline-block mx-auto "

                  
                ></img>
               </a>
              </div>

              <div
                className=" col-lg-2 col-xl-2  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/"
                   
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/About"
                    
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Products"
                 
                  >
                    Products
                  </a>
                </p>
                {/* <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Blogs"
                   
                  >
                    Blogs
                  </a>
                </p> */}
                <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Newsevent"
                    
                  >
                     News & Events
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px" }}
                    href="/Contact"
                    
                  >
                    Contact Us
                  </a>
                </p>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <div style={{display:"flex"}}>
                <p>
                   <i class="fa-solid fa-location-dot fa-lg mr-3 "></i>
                
                </p>
                <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://maps.app.goo.gl/Qe6AeaLbJ8QXFPXv6"
                    target="_blank"
                    rel="nopper"
                    className="mx-3"
                  >    
                    
                    Godown No. 3-4-5, Prince Estate, Behind Shyam Sangini Market, Saniya - Hemad Road, Saroli, Surat - 395010
                  </a>
                  </div>
                <p className="mt-3">
                <i class="fa-solid fa-phone fa-lg mr-3 "></i>
                   
                  
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="tel:9173664364"
                    className="mx-2"
                  >
                    {" "}
                    +91 91736 64364
                  </a>
                </p>
                <p>
                  <i className="fas fa-envelope fa-lg mr-3 ">
                   
                  </i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="mailto:srwater031987@gmail.com"
                    target="_blank"
                    rel="nopper"
                    className="mx-2"
                  >
                    {" "}
                    srwater031987@gmail.com
                  </a>
                </p>
              </div>
              <div
                className=" col-lg-3 col-xl-3  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Business Hours</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <h6
                  >
                    Monday-Saturday: 9AM to 7PM
                  </h6>
                </p>
                <p>
                  <h6
                  >
                    Sunday: Closed
                  </h6>
                </p>
                <p className="my-2" style={{display:"flex"}}>
              <a
                className=""
                href={"tel:9173664364" }
                style={{ textDecoration: "none" }}
              >
                 <img  width={35} src={call} alt=""  style={{marginRight:"5px"}}/>
              </a>
              <a
              className=""
                href={"https://wa.me/9173664364" }
                target="_blank"
                rel="nopper"
              >
                <img  width={35} src={what} alt="" style={{marginRight:"5px"}}/> 
              </a>
              <a
              className=""
                style={{
                  textDecoration: "none",
                  color: "black",
                
                }}
                href={"mailto:srwater031987@gmail.com" }
                target="_blank"
                rel="nopper"
              >
                <img src={maill} width={35} alt="" style={{marginRight:"5px"}}/>
              </a>
              <a
              className=""
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                href={ 'https://www.facebook.com/srwatergroup123?sfnsn=wiwspwa&mibextid=RUbZ1f'}
                target="_blank"
                rel="nopper"
              >
                <img src={fb} width={35} alt="" style={{marginRight:"5px"}}/>
              </a>
              <a
              className=""
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                href={ 'https://www.instagram.com/srwatergroup?igsh=bTdxMGthMWllajY0'}
                target="_blank"
                rel="nopper"
              >
                <img src={instagram} width={35} alt="" style={{marginRight:"5px"}}/>
              </a>
            </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3 wavesh" >
          <p>Copyright © 2023. SR ENTERPRISE | All Rights Reserved.</p>
          DESIGN & DEVELOPED BY:{" "}
          <a
            target="_blank"
            rel="nopper"
            style={{ textDecoration: "none" }}
            href="https://teamflourish.co/"
          >
            FLOURISH CREATIONS PVT LTD{" "}
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Product;
