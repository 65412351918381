 import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  ModalCloseButton,
  extendTheme,
  Flex,
  Image,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import "./Product.css";

const ProductPopUp = ({ name, spec, desc, mainImage,price}) => {
  const url = `https://api.srwater.in`;
  // const url="http://192.168.1.19:8000"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dataArray = Object.entries(spec || {});
  const [major,setMajor]=useState(0)
  const BASE_URL = "https://api.srwater.in/product";



  const handleClick = (i) => {
    setMajor(i)
  };

  // const getCategory = async () => {
  //   try {
  //     let data = await fetch(`https://api.srwater.in/product/${image}`);
  //     console.log(image)
  //     data = await data.json();
  //     // console.log(data);
  //     // setMajor(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    // setMajor(ele[0])
    // console.log(mainImage[0],name);
  }, []);

  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: (props) => ({
          dialog: {
            maxWidth: ["80%", "80%", "80%"],
            minHeight: ["70%", "70%", "70%"],
            bg: "#f7f7f7",

            boxShadow: "none",
          },
        }),
      },
    },
  });
  return (
    <div className="" onClick={onOpen} >
      <div style={{position:"relative",border: "1px solid black",borderRadius: "10px",padding:"10px 0"}}>
        <img
                      className="img container"
                      alt=""
                      style={{
                        width: "250px",
                        alignItems: "center",
                        textAlign: "center",
                        // borderRadius: "10px",
                        objectFit: "cover",
                        // border: "1px solid black",
                      }}
                      src={BASE_URL + "/" + mainImage?.[0]}
                    />
                    {price&&<div class="top-right">₹{price}</div>}
                    
                    </div>
                    {/* <Text className="my-2" style={{fontSize:"20px",fontWeight:"bold"}}>{name}</Text> */}
      <Button
        className="my-3"
        style={{
          textAlign: "center",
          alignItems: "center",
          background: "#E7E7E7",
          padding: "20px 20px",
          paddingBottom: "20px",
        }}
        // onClick={onOpen}
      >
        {name} <FaArrowRight />
      </Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        theme={theme}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {mainImage[major]?<ModalBody className="modals">
            <div className="col-lg-6 col-xl-6">
              <Box>
               {mainImage[major]&&<Image src={url + "/product/" + mainImage?.[major]} />}
                <Flex justifyContent={"center"} gap="10px">
                  {mainImage?.length>0 &&
                    mainImage?.map((e,i) => {
                      return (
                       e&& <Image
                          width={"80px"}
                          _hover={{ border: "2px solid black" }}
                          src={`${url}/product/${e}`}
                          alt="error image"
                          onClick={() => handleClick(i)}
                        />
                      );
                    })}
                </Flex>
              </Box>
            </div>
            <div className="col-lg-6 col-xl-6">
              <Text textAlign={"Center"} fontWeight="bold" fontSize={30}  mb="1rem">
                {name.toUpperCase()}
              </Text>
              <Text textAlign={"Center"} fontWeight="bold" mb="1rem">
                {desc}
              </Text>
              {spec && (
                <div className="pt-3 pb-0 px-3" style={{}}>
                  <table className=" ptab table  ">
                    <thead className="">
                      <tr
                        style={{
                          backgroundColor: "#D20000",
                          color: "white",
                          textAlign: "center",
                          alignItems: "center",
                          fontSize: "20px",
                        }}
                      >
                        <th className="th" scope="col">
                          Parameter
                        </th>
                        <th className="th" scope="col">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        backgroundColor: "whitesmoke",
                        border: "1px solid black",
                      }}
                    >
                      {dataArray.map(([parameter, value]) => (
                        <tr key={parameter}>
                          <td className="td">{parameter}</td>
                          <td className="td">{value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              <Text className="text-center mt-2">
                <button className="btn btn1">
                  <a
                    href="/Contact"
                    
                  >
                    Inquiry Now
                    
                  </a>
                </button>
              </Text>
            </div>
          </ModalBody>:
          <ModalBody>
             <div className="cantainer">
              <Text textAlign={"Center"} fontWeight="bold" fontSize={30}  mb="1rem">
                {name.toUpperCase()}
              </Text>
              <Text textAlign={"Center"} fontWeight="bold" mb="1rem">
                {desc}
              </Text>
              {spec && (
                <div className="pt-3 pb-0 px-3" style={{}}>
                  <table className=" ptab table  ">
                    <thead className="">
                      <tr
                        style={{
                          backgroundColor: "#D20000",
                          color: "white",
                          textAlign: "center",
                          alignItems: "center",
                          fontSize: "20px",
                        }}
                      >
                        <th className="th" scope="col">
                          Parameter
                        </th>
                        <th className="th" scope="col">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        backgroundColor: "whitesmoke",
                        border: "1px solid black",
                      }}
                    >
                      {dataArray.map(([parameter, value]) => (
                        <tr key={parameter}>
                          <td className="td">{parameter}</td>
                          <td className="td">{value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              <Text className="text-center mt-2">
                <button>
                  <a
                    href="/Contact"
                    class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold shadow text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group"
                  >
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                      <svg
                        class="w-5 h-5 text-green-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                           stroke-width="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                      <svg
                        class="w-5 h-5 text-green-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
                      Inquery Now
                    </span>
                  </a>
                </button>
              </Text>
            </div>
          </ModalBody>
          }
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProductPopUp;
