import React from "react";
import "./Newsevent.css";
import srlogo from "./images/srlogo.png";
import flonixlogo from "./images/flonixlogo.png";
import "./Navbar.css";
import "./Footer.css";
import { useEffect, useState } from "react";
import call from "./images/call.png";
import what from "./images/what.png";
import maill from "./images/maill.png";
import fb from "./images/fb.png";
import instagram from "./images/instagram.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Lorem
} from '@chakra-ui/react'
import EventModal from "./EventModal";
import navContext from "../context/navDetail";
import { useContext } from "react";
function Newsevent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const {schemeData}=useContext(navContext)


  const url = "https://api.srwater.in/news";
  // let url="http://192.168.1.12:8000/news"

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((x) => {
        setData(x.data);
        // console.log(x.data[0]);
      });
  }, []);

  return (
    <div className="fond">
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
        style={{ width: "100%" }}
      >
        <div
          className="container-fluid"
          style={{ justifyContent: "space-around" }}
        >
          <a className="navbar-brand fw-bold" href="/">
            <img style={{ width: "100px" }} src={srlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-3">
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/About">
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Product">
                  Products
                </a>
              </li>
              {/* <li className="nav-item mx-3">
                <a className="nav-link" href="/Blogs">
                  Blogs
                </a>
              </li> */}

              <li className="nav-item mx-3">
                <a className="nav-link active" href="/Newsevent">
                  News & Events
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Ebroucher">
                  E-Brochure
                </a>
              </li>
              <li className="nav-item mx-3">
              <a style={{fontWeight:"bolder"}} className="nav-link changingcolour" href="/AnnualScheme">
                {schemeData}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="aboutbanner">
        <div className="banner-text">
          <h1 className="aboutus">News & Events</h1>
        </div>
      </section>
      <section className="my-5">
        <div className="container ">
          <div className="middle-boxx ">
            {data?.length &&
              data.map((e) => {
                return (
                  <div className="container conts" onClick={onOpen}>
                    
                  
                   <EventModal image={e.image} place={e.place} date={e.date}  video={e.video} title={e.title} album={e.album} />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <footer style={{ marginBottom: "-1000px" }}>
        <svg viewBox="0 0 120 28">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="1"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                result="goo"
              />
              <xfeBlend in="SourceGraphic" in2="goo" />
            </filter>
            <path
              id="wave"
              d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
            />
          </defs>

          <use id="wave3" class="wave" href="#wave" x="0" y="-2"></use>
          <use id="wave2" class="wave" href="#wave" x="0" y="0"></use>

          <g class="gooeff" filter="url(#goo)">
            <use id="wave1" class="wave" href="#wave" x="0" y="1" />
          </g>

          <path
            id="wave1"
            class="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </svg>

        <section className="wavesh">
          <div className="container text-center   ">
            <div className="row ">
              <div className="col-lg-3 col-xl-3   ">
                <a href="/">
                  <img
                    style={{ width: "150px", alignItems: "left" }}
                    src={flonixlogo}
                    className=" d-inline-block mx-auto "
                  ></img>
                </a>
              </div>

              <div
                className=" col-lg-2 col-xl-2  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/"
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/About"
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Products"
                  >
                    Products
                  </a>
                </p>
                {/* <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Blogs"
                   
                  >
                    Blogs
                  </a>
                </p> */}
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Newsevent"
                  >
                    News & Events
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Contact"
                  >
                    Contact Us
                  </a>
                </p>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <p>
                    <i class="fa-solid fa-location-dot fa-lg mr-3 "></i>
                  </p>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://maps.app.goo.gl/Qe6AeaLbJ8QXFPXv6"
                    target="_blank"
                    rel="nopper"
                    className="mx-3"
                  >
                    Godown No. 3-4-5, Prince Estate, Behind Shyam Sangini
                    Market, Saniya - Hemad Road, Saroli, Surat - 395010
                  </a>
                </div>
                <p className="mt-3">
                  <i class="fa-solid fa-phone fa-lg mr-3 "></i>

                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="tel:9173664364"
                    className="mx-2"
                  >
                    {" "}
                    +91 91736 64364
                  </a>
                </p>
                <p>
                  <i className="fas fa-envelope fa-lg mr-3 "></i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="mailto:srwater031987@gmail.com"
                    target="_blank"
                    rel="nopper"
                    className="mx-2"
                  >
                    {" "}
                    srwater031987@gmail.com
                  </a>
                </p>
              </div>
              <div
                className=" col-lg-3 col-xl-3  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Business Hours</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <h6>Monday-Saturday: 9AM to 7PM</h6>
                </p>
                <p>
                  <h6>Sunday: Closed</h6>
                </p>
                <p className="my-2" style={{ display: "flex" }}>
                  <a
                    className=""
                    href={"tel:9173664364"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      width={35}
                      src={call}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    href={"https://wa.me/9173664364"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      width={35}
                      src={what}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"mailto:srwater031987@gmail.com"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={maill}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"https://www.facebook.com/srwatergroup123?sfnsn=wiwspwa&mibextid=RUbZ1f"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={fb}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"https://www.instagram.com/srwatergroup?igsh=bTdxMGthMWllajY0"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={instagram}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3 wavesh">
          <p>Copyright © 2023. SR ENTERPRISE | All Rights Reserved.</p>
          DESIGN & DEVELOPED BY:{" "}
          <a
            target="_blank"
            rel="nopper"
            style={{ textDecoration: "none" }}
            href="https://teamflourish.co/"
          >
            FLOURISH CREATIONS PVT LTD{" "}
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Newsevent;
