import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useLayoutEffect, useRef, useState } from "react";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventModal = ({ image, place, date, title, video, album }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const flexContainerRef = useRef(null);
  const [selected, setSelected] = useState("");
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    
    slidesToShow: 3,
    speed: 500,
    nextArrow: <IoIosArrowDropright color="black" />,
    prevArrow: <IoIosArrowDropleft color="black" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 3

        }
      }
    ]
  };

  const handleScroll = (scrollDirection) => {
    const container = flexContainerRef.current;

    if (container) {
      const scrollAmount = container.clientWidth / 2;
      console.log(scrollAmount); // Adjust the scroll amount based on your design
      if (scrollDirection === "left") {
        container.scrollLeft -= scrollAmount;
        console.log(container.scrollLeft);
      } else if (scrollDirection === "right") {
        container.scrollLeft += scrollAmount;
        console.log(container.scrollLeft);
      }
    }
  };

  useLayoutEffect(() => {
    if (video) {
      setSelected(`https://www.youtube.com/embed/${video}`);
    } else if (album.length) {
      setSelected(`https://api.srwater.in/news/${album[0]}`);
    } else {
      setSelected(`https://api.srwater.in/news/${image}`);
    }
  }, []);

  return (
    <div onClick={onOpen}>
      <img
        style={{ width: "300px", height: "180px" }}
        className="  container image  "
        src={"https://api.srwater.in/news/" + image}
        alt=""
      />
      <p
        style={{ fontSize: "20px", fontWeight: "bolder" }}
        className="text-center py-1"
      >
        {title}
      </p>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent backgroundColor={"#fafafa"}>
          <ModalHeader style={{ textAlign: "center", fontSize: "40px" }}>
            <div className="middle">
              <div
                style={{ textAlign: "center", fontSize: "32px",padding:"0" }}
                className="text"
              >
                {place}
              </div>
              <div style={{ textAlign: "center",padding:"0" }} className="text">
                {date}
              </div>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <img
              style={{ width: "400px", height: "250px" }}
              className="  container image  "
              src={"http://192.168.1.12:8000/news/" + image}
              alt=""
            /> */}
            <Box>
              <Flex justifyContent={"center"}>
                {selected == `https://www.youtube.com/embed/${video}` ? (
                  <iframe
                    src={selected}
                    title="YouTube video player"
                    frameborder="0"
                    style={{
                      borderRadius: "20px",
                      aspectRatio:"4/2",
                      width:"100%",

                      objectFit: "cover",
                    }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;full-screen"
                    allowfullscreen
                  ></iframe>
                ) : (
                  <Image
                    src={selected}
                    width={"100%"}
                    // height={["200px","200px","200px","300px","300px"]}
                    objectFit={"cover"}
                    borderRadius={"20px"}
                    aspectRatio={"4/2"}
                    // height={"550px"}
                    _hover={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}
                  />
                )}
              </Flex>
              <Flex justifyContent={"center"}>
                <Slider
                  {...settings}
                  style={{ width: "80%", marginTop: "20px" }}
                >
                  {video && (
                    <>
                      <Image
                        src={`https://img.youtube.com/vi/${video}/0.jpg`}
                        alt="Thumbnail"
                        _hover={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                        }}
                        w={["100px", "100px", "100px", "200px","200px"]}
                          // h={["50px", "50px", "50px", "100px","100px"]}
                          aspectRatio={"16/9"}
                        objectFit={"cover"}
                        borderRadius={"20px"}
                        cursor={"pointer"}
                        gap={"5px"}
                        onClick={() =>
                          setSelected(`https://www.youtube.com/embed/${video}`)
                        }
                      />
                    </>
                  )}
                  {album?.map((e) => {
                    return (
                      <Box>
                        <Image
                          src={`https://api.srwater.in/news/${e}`}
                          borderRadius={"20px"}
                          objectFit={"cover"}
                          w={["100px", "100px", "100px", "200px","200px"]}
                          // h={["50px", "50px", "50px", "100px","100px"]}
                          aspectRatio={"16/9"}
                          gap={"5px"}
                          onClick={() =>
                            setSelected(`https://api.srwater.in/news/${e}`)
                          }
                          _hover={{
                            cursor: "pointer",

                            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                          }}
                        />
                      </Box>
                    );
                  })}
                </Slider>
              </Flex>
              {/* <Flex  mt={"20px"} justifyContent={"center"}  >
                <Button>
                  <IoIosArrowDropleft
                    onClick={() => handleScroll("left")}
                    size={"30px"}
                  />
                </Button>
                <Flex
                  
                  ref={flexContainerRef}
                  overflowX={"scroll"}
                  width={"500px"}
                  gap={"20px"}
                  justifyContent={"space-between"}
                >
                    {video && (
                      <Image
                        src={`https://img.youtube.com/vi/${video}/0.jpg`}
                        alt="Thumbnail"
                        _hover={{
                          
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                        }}
                        width={"200px"} 
                        height={"100px"}
                        borderRadius={"20px"}
                        cursor={"pointer"}
                        onClick={() =>
                          setSelected(`https://www.youtube.com/embed/${video}`)
                        }
                      />
                    )}
                  {album?.map((e) => {
                    return (
                      <Box>
                        <Image
                          src={`http://192.168.1.12:8000/news/${e}`}
                          width={"200px"}
                          height={"100px"}
                          borderRadius={"20px"}
                          onClick={() =>
                            setSelected(`http://192.168.1.12:8000/news/${e}`)
                          }
                          _hover={{
                            cursor: "pointer",
                            
                            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                          }}
                        />
                      </Box>
                    );
                  })}
               
                </Flex>
                <Button >
                  <IoIosArrowDropright
                    onClick={() => handleScroll("right")}
                    size="30px"
                  />
                </Button>
              </Flex> */}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EventModal;
