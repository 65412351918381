import {  Routes, Route,  } from  'react-router-dom';
// import Navbar from "./Components/Navbar"
import Home from './Components/Home';
import About from './Components/About';
import Product from './Components/Product';
// import Blogs from './Components/Blogs';
// import Blogs1 from './Components/Blogs1';

import Newsevent from './Components/Newsevent';
import Contact from './Components/Contact';
// import Footer from './Components/Footer';
import Card from './Components/Card';
import Ebroucher from './Components/Ebroucher';
import AnnualScheme from "./Components/AnnualScheme";

import './App.css';
import NotFoundPage from './Components/NotFoundPage';
import AnulPopUp from './Components/AnulPopUp';
import AnnulTokenNumber from './Components/AnnulTokenNumber';




function App() {
  return (
    <>

    <Routes >
      <Route path='*' element={<NotFoundPage/>}/>
      <Route path="/" element={ <Home/> }/>
      <Route  path="/About" element={ <About/> } />
      <Route path="/Product" element={ <Product/> } />
      {/* <Route path="/Blogs" element={ <Blogs/> } /> */}
      {/* <Route path="/Blogs1/:id" element={ <Blogs1/> }/> */}
      
      <Route path="/Newsevent" element={ <Newsevent/> } />
      <Route path="/Contact" element={ <Contact/> } />
      <Route path="/Ebroucher" element={ <Ebroucher/> } />
      <Route path="/AnnualScheme" element={ <AnnualScheme/> } />
      <Route path="/AnulPopUp" element={ <AnulPopUp/> } />
      <Route path="/AnulPopUp" element={ <AnnulTokenNumber/> } />
      <Route path="/Card/:names" element={ <Card/> } />
     
       
       
  </Routes>
  </>
  );
}

export default App;

