import React, { useEffect } from "react";
import "./Contact.css";
import boll from "./images/boll.png";
import mail from "./images/mail.png";
import location from "./images/location.png";
import telephone from "./images/telephone.png";
import srlogo from "./images/srlogo.png";
import flonixlogo from "./images/flonixlogo.png";
import "./Navbar.css";
import "./Footer.css";
import { useState } from "react";
import call from "./images/call.png";
import what from "./images/what.png";
import maill from "./images/maill.png";
import fb from "./images/fb.png";
import instagram from "./images/instagram.png";
import number from "./images/number.png";
import destination from "./images/destination.png";
import navContext from "../context/navDetail";
import { useContext } from "react";

function Contact() {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [outlet,setOutlet]=useState([])
  const {schemeData}=useContext(navContext)




  const getData=async()=>{
    try {
      let data=await fetch(`https://api.srwater.in/store`)
      data=await data.json()
      setOutlet(data.data)
    } catch (error) {
      console.log(error);
    }
  }


  const postPutEvent = async () => {
    const data = {
      name,
      email,
      phone,
      message,
    };


    const url = "https://api.srwater.in/contact";


    console.log(data);
    if(!name||!email||!phone||!message){
      alert("Please enter All The Fields")
    }else{

      try {
        let res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(data),
        });
        res = await res.json();
        alert("mail sent");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(()=>{
getData()
  },[])
  return (
    <div className="fond">
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
        style={{ width: "100%" }}
      >
        <div
          className="container-fluid"
          style={{ justifyContent: "space-around" }}
        >
          <a className="navbar-brand fw-bold" href="/">
            <img style={{ width: "100px" }} src={srlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-3">
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/About">
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Product">
                  Products
                </a>
              </li>
              {/* <li className="nav-item mx-3">
                <a className="nav-link" href="/Blogs">
                  Blogs
                </a>
              </li> */}

              <li className="nav-item mx-3">
                <a className="nav-link" href="/Newsevent">
                  News & Events
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link active" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Ebroucher">
                  E-Brochure
                </a>
              </li>
              <li className="nav-item mx-3">
              <a style={{fontWeight:"bolder"}} className="nav-link changingcolour" href="/AnnualScheme">
              {schemeData}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="aboutbanner">
        <div className="banner-text">
          <h1 className="aboutus">Contact Us</h1>
        </div>
      </section>
      <section className="my-5" id="contact">
        <div className="container">
          <h1 className="text-center mt-5 sendus">Send Us A Message </h1>
          <div className="row" style={{ alignItems: "center" }}>
            <img className="boll1" src={boll} alt="" />
            <div className="col-lg-5">
              <div
                className="container"
                style={{
                  background: "black",
                  color: "white",
                  textAlign: "justify",
                  padding: "50px 20px ",
                }}
              >
               
                <div className=" w-100 d-flex align-items-start ">
                  <div className="icon d-flex align-items-center   ">
                    {" "}
                    <img style={{ width: "50px" }} src={location} alt="" />
                    <span
                      style={{
                        fontSize: "16px",
                        color: "white",
                        paddingLeft: "15px",
                      }}
                    >
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        target="_blank"
                        rel="nopper"
                        href="https://maps.app.goo.gl/Qe6AeaLbJ8QXFPXv6"
                      >
                        Godown No. 3-4-5, Prince Estate, Behind Shyam Sangini
                        Market, Saniya - Hemad Road, Saroli, Surat - 395010
                      </a>
                    </span>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-start my-2 ">
                  <div className=" icon d-flex align-items-center justify-content-center  ">
                    <span>
                      {" "}
                      <img style={{ width: "50px" }} src={telephone} alt="" />
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        
                        color: "white",
                        paddingLeft: "15px",
                      }}
                    >
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href="tel:9173664364"
                      >
                        91+ 91736 64364
                      </a>
                    </span>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-start my-2">
                  <div className="icon d-flex align-items-center justify-content-center  ">
                    <span>
                      {" "}
                      <img style={{ width: "50px" }} src={mail} alt="" />
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        
                        color: "white",
                        paddingLeft: "15px",
                      }}
                    >
                      <a
                        style={{ color: "white", textDecoration: "none" }}
                        href="mailto:srwater031987@gmail.com"
                      >
                        srwater031987@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <img className="boll2" src={boll} alt="" />

            <div className="col-lg-6 my-5">
              <form>
                <div className="mb-3">
                  <input
                    style={{ border: "1px solid black" }}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="Name"
                    placeholder="Full name*"
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>
                <div className="mb-3">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ border: "1px solid black" }}
                    type="Name"
                    placeholder="Email*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>

                <div className="mb-3">
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    style={{ border: "1px solid black" }}
                    type="tel"
                    placeholder="Phone*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    style={{ border: "1px solid black" }}
                    type="text area"
                    placeholder="Message*"
                    className="form-control"
                    rows="3"
                    required
                  />
                </div>

                <button
                className="btn btn1"
                  onClick={postPutEvent}
                  type="submit"
                  id="contact-submit"
                  data-submit="...Sending"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
      <h1 style={{fontWeight:"bolder"}} className="text-center">Our Outlets</h1>
        <div className="container">
        
          {/* <div className="row  ">
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                <div className="col-xl-12 col-lg-12 my-4 ">
                 
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S R WATER WROLD
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            plot no 08, shop no 2 akshar palace swaminarayan
                            nagar Lalita chokdi, katargam , surat 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 99093 32998</p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-lg-4 col-xl-4  pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S R WATER FILTER HOUSE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            PLOT NO.55, SHOP NO. 2 SAI BABA SOCIETY, NR. PIYUSH
                            POINT, 120 FEET BAMROLI ROAD, PANDESARA, SURAT
                            394241
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 "93135 13053 "</p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-lg-4 col-xl-4  pt-4" >
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S R MARKETING
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            FIRM ADDRESS: SHOP NO 9, MORNING GLORY, HUBTOWN
                            GARDENIA, NEAR GCC CLUB, HATKESH ROAD, MIRAROAD
                            EAST,MUMBAI 401107
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 77376 88991</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  <div className="col-lg-4 col-xl-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S.R. WATER CARE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            SHOP NO.3, GANESH APPARTMENT, VARSHA SOCIETY-1 L.H.
                            ROAD, VARACHHA 395006
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 82388 61082</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        HARI OM SALES & SERVICES
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            442 Nashik road Gunjalanagar Tal Deola Dist.Nashik
                            pin. 423102
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 94218 04629</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S R WATER SOLUTION
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            G-9, Mega Royal Complex, Honey Park road, Surbhi
                            Dairy Circle,Adajan, 395009
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 99091 13399</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="col-lg-4 col-xl-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S K MARKETING
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            Shop No. 14&15 Tirupati Trade Center, Navghar Road,
                            Vasai East, 401208
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 90223 01767</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        KARMA WATER TECH
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            59 sahajanand kutir industry besides tata motors
                            mujmahuda aksharchok road vadodara 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 968708 0809</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        KDL WATER SOLUTION
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            SHOP NO 465, RUSHI NAGAR, MAHARANA PATAP CHOCK,
                            GODADRA SURAT, 395010
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 99793 40645</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="col-lg-4 col-xl-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        MAHAKALI ENTERPRISE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            107 Bhikh Rdaji Regancy OPP - DCB Bank silvaass
                            Road GIDC Char Rasta vapi 396195 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 99254 34147</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        SAHAJ MARKETING
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            G 18 , R- 21 NR AMBEGREEN SOC., VALIA ROAD ,
                            KOSMADI . ANKLESHWAR 393002 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 96013 03637</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        JOYSTICK BIO CARE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            next to suzuki showroom dharmpur road valsad
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 97141 63052</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  <div className="col-lg-4 col-xl-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        SHLOK WATER TECHNOLOG
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            Shop No.2, Ground Floor, Nirali Complex, Opp. Bank
                            Of Baroda, Shastri Road, Bardoli. 394601
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 96385 04040</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        VISHVKARMA MULTISOLUTION
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            136, D wing, Ground floor,New B.J. Market JALGAON
                            MAHARASHTRA- 425001
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 96237 45546</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 pt-4 pt-4">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        KHUSHI ENTERPRISE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            shop no.4 Akshar darshan society kala nagar dindori
                            road mhasrul Nashik 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 79727 81263</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 ">
              <div className="service-detail-text">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                      type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        SAMRTH WATER PURIFIER SALES AND SERVICES
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            Office no 1,Om niwas plot no 73, RSC 19/120,
                            sawarkar nagar opp Sankalp School thane west 400606.
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 98920 45203</p>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-4 col-xl-4 pt-4 ">
                    <div className="mainparent card-1" id="contact">
                      <button
                        type="submit"
                        className="topbutton"
                        style={{
                          cursor: "default",
                        }}
                      >
                        S R WATER LINE
                      </button>

                      <div>
                        <br />
                        <div className=" mainchild">
                          <img width={"45px"} src={destination} alt="" />
                          &nbsp;
                          <p className="mainpera">
                            PLOT NO. 92, JIVANDHARA SOC., NEAR GANGAJAMUNA
                            SOC., BESIDE TAPOVAN SCHOOL, TAPOVAN CIRCLE,
                            CHIKUWADI ROAD VARCHHA 
                          </p>
                        </div>
                        <br />
                        <div className="mainchild">
                          <img width={"45px"} src={number} alt="" />
                          &nbsp;
                          <p className="mainpera">+91 99135 07720</p>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  
                  <div className="col-lg-4 col-xl-4 pt-4 pt-4">
                    <div className="mainparent card-1 soon"  >
                         
                         <h1 style={{alignItems:"center"}}  className="  coming changingcolour" >
                         COMING SOON
                         </h1>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="outlet">
            {outlet.length&&outlet.map((e)=>
            <div className="mainparent card-1" id="contact">
            <button
            type="submit"
              className="topbutton"
              style={{
                cursor: "default",
              }}
            >
             {e?.name}
            </button>

            <div>
              <br />
              <div className=" mainchild">
                <img width={"45px"} src={destination} alt="" />
                &nbsp;
                <p className="mainpera">
                  {e?.address}
                </p>
              </div>
              <br />
              <div className="mainchild">
                <img width={"45px"} src={number} alt="" />
                &nbsp;
                <p className="mainpera">{e?.contact}</p>
              </div>
            </div>
          </div>
            )
            }
          </div>
        </div>
      </section>
      <footer style={{ marginBottom: "-1000px" }}>
        <svg viewBox="0 0 120 28">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="1"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                result="goo"
              />
              <xfeBlend in="SourceGraphic" in2="goo" />
            </filter>
            <path
              id="wave"
              d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
            />
          </defs>

          <use id="wave3" class="wave" href="#wave" x="0" y="-2"></use>
          <use id="wave2" class="wave" href="#wave" x="0" y="0"></use>

          <g class="gooeff" filter="url(#goo)">
            <use id="wave1" class="wave" href="#wave" x="0" y="1" />
          </g>

          <path
            id="wave1"
            class="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </svg>

        <section className="wavesh">
          <div className="container text-center   ">
            <div className="row ">
              <div className="col-lg-3 col-xl-3   ">
                <a href="/">
                  <img
                    style={{ width: "150px", alignItems: "left" }}
                    src={flonixlogo}
                    className=" d-inline-block mx-auto "
                  ></img>
                </a>
              </div>

              <div
                className=" col-lg-2 col-xl-2  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/"
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/About"
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Products"
                  >
                    Products
                  </a>
                </p>
                {/* <p>
                  <a
                    style={{ textDecoration: "none",color:"white",fontSize:"20px"  }}
                    href="/Blogs"
                   
                  >
                    Blogs
                  </a>
                </p> */}
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Newsevent"
                  >
                    News & Events
                  </a>
                </p>
                <p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: "20px",
                    }}
                    href="/Contact"
                  >
                    Contact Us
                  </a>
                </p>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <p>
                    <i class="fa-solid fa-location-dot fa-lg mr-3 "></i>
                  </p>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://maps.app.goo.gl/Qe6AeaLbJ8QXFPXv6"
                    target="_blank"
                    rel="nopper"
                    className="mx-3"
                  >
                    Godown No. 3-4-5, Prince Estate, Behind Shyam Sangini
                    Market, Saniya - Hemad Road, Saroli, Surat - 395010
                  </a>
                </div>
                <p className="mt-3">
                  <i class="fa-solid fa-phone fa-lg mr-3 "></i>

                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="tel:9173664364"
                    className="mx-2"
                  >
                    {" "}
                    +91 91736 64364
                  </a>
                </p>
                <p>
                  <i className="fas fa-envelope fa-lg mr-3 "></i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="mailto:srwater031987@gmail.com"
                    target="_blank"
                    rel="nopper"
                    className="mx-2"
                  >
                    {" "}
                    srwater031987@gmail.com
                  </a>
                </p>
              </div>
              <div
                className=" col-lg-3 col-xl-3  "
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Business Hours</h6>
                <hr
                  className=" mt-0 "
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <h6>Monday-Saturday: 9AM to 7PM</h6>
                </p>
                <p>
                  <h6>Sunday: Closed</h6>
                </p>
                <p className="my-2" style={{ display: "flex" }}>
                  <a
                    className=""
                    href={"tel:9173664364"}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      width={35}
                      src={call}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    href={"https://wa.me/9173664364"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      width={35}
                      src={what}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"mailto:srwater031987@gmail.com"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={maill}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"https://www.facebook.com/srwatergroup123?sfnsn=wiwspwa&mibextid=RUbZ1f"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={fb}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                  <a
                    className=""
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={"https://www.instagram.com/srwatergroup?igsh=bTdxMGthMWllajY0"}
                    target="_blank"
                    rel="nopper"
                  >
                    <img
                      src={instagram}
                      width={35}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3 wavesh">
          <p>Copyright © 2023. SR ENTERPRISE | All Rights Reserved.</p>
          DESIGN & DEVELOPED BY:{" "}
          <a
            target="_blank"
            rel="nopper"
            style={{ textDecoration: "none" }}
            href="https://teamflourish.co/"
          >
            FLOURISH CREATIONS PVT LTD{" "}
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
