import React from "react";

import "./AnnualScheme.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  extendTheme,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
const AnnulTokenNumber = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("md");
  const [token, setToken] = useState("");
  const toast = useToast();

  const handleSizeClick = async (e,newSize) => {
    if(e.detail===1){
      if(!data.name){
        alert("Please Enter a Name")
      }else if(!data.challan_no){
        alert("Please Enter a Challan Number")
      }else if(!data.amount){
        alert("Please Enter a Amount")
      }else if(!data.mobile){
        alert("Please Enter a Mobile Number")
      }else{
      try {
        let res = await fetch(`https://api.srwater.in/annual/add`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        });
        res = await res.json();
        if (res.data) {
          setToken(res.data.token);
          try {

            let x = await fetch(`https://dealsms.in/api/send?type=text&instance_id=65C61737A7522&access_token=657017243e806&message=hiii ${data?.name} your token number is ${res.data.token},Your Challan Number is ${data?.challan_no} and Payable Amount is ${data?.amount}. Thank You ! &number=91${data.mobile}`);
            x=await x.json()

          } catch (error) {
            toast({
              title: "Web browser Error",
              position: "top",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
          onOpen();
        } else {
          toast({
            title: res.msg,
            position: "top",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Please Check Entered Fields",
          position: "top",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    }else{
      return
    }
  };
  const sizes = ["full"];
  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: (props) => ({
          dialog: {
            maxWidth: ["50%", "50%", "50%"],
            minHeight: ["30%", "30%", "30%"],
            bg: "#f7f7f7",
            boxShadow: "none",
          },
        }),
      },
    },
  });
  return (
    <>
      {sizes.map((size) => (
        <Button
          _hover={{
            borderColor: "#151A27",
            color: "#151A27",
            bgColor: "white",
          }}
          bgColor={"#151A27"}
          color="white"
          onClick={(e) => handleSizeClick(e,size)}
          key={size}
          m={4}
        >
          Submit
        </Button>
      ))}
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        theme={theme}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            style={{
              background: "#151A27",
              border: "0.1px solid white",
              borderRadius: "8px",
            }}
          >
            <div style={{ textAlign: "center", margin: "110px 0 110px 0" }}>
              <h1 style={{ color: "white" }}>Token No.</h1>
              <h1 className="my-5" style={{ color: "white" }}>
                {token}
              </h1>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnnulTokenNumber;
