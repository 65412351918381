import React from "react";
import "./Cardproduct.css"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Cardproduct() {
  const [product, setProduct] = useState([]);
  const [productsData, setProductsData] = useState( );
  const {names}=useParams()
  
  useEffect(() => {
    getProduct();
  }, []);

 
  
  const getProduct = async () => {
    try {
      // debugger;
      console.log(names);
      let data = await fetch(`https://api.srwater.in/card/${names}`);
      data = await data.json();
      // console.log(data.data);
      console.log(data.data[0].products);
      setProduct(data.data[0].products);
      let products = [];
      let promises = [];

      data.data[0].products.map(async (e) => {
        promises.push(getDetails(e))
      });
      let resp = await Promise.all(promises);
      setProductsData(resp)
    } catch (error) {
      console.log(error);
    }
  };

  // const cheeckProduct=async(id)=>{
  //   try {
  //     let data=await fetch(`http://192.168.1.22:8080/product/${id}`)
  //     data=await data.json()
  //     if(data.data){
  //       return true
  //     }else{
  //       return false
  //     }
  //   } catch (error) {
      
  //   }
  // }

  const getDetails = async (id) => {
    try {
      console.log(id);
      let data = await fetch(`https://api.srwater.in/product/${id}`);
      data = await data.json();
      console.log(data.data, "details");
      console.log(data.data)
      return data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
 
  // const [product, setProduct] = useState({});
  // const getItem=async()=>{
  //   try {
  //     let data=await fetch("http://192.168.1.12:8080/product/")
  //         data=await data.json()
  //       //   console.log(data.data);
  //         setProduct(data.data)
  //   } catch (error) {
  //     console.log(error);
  //   }
  //  }
  //  useEffect(()=>{
  //    getItem()
    
  //  },[])

  useEffect(() => {
    getProduct()
    console.log("product",productsData)
  }, [setProductsData])

  return (
    <>
      
        <div className="container my-5 " id="Cardproduct">
         
                  <div className=" middle-box   " >
                    {productsData?.length &&
                      productsData?.map((e) => {
                        return (
                          e&&<div key={e?._id} style={{border:"1px solid gray",alignItems:"center",textAlign:"center",background:"white"}}>
                            <img
                           
                              style={{  padding:"0 auto" }}
                              src={`https://api.srwater.in/product/${e?.image[0]}`}
                              alt="img error"
                            />
                            <p className="mt-3" style={{fontSize:"20px",fontWeight:"400"}} >{e?.name}</p>
                            <hr />
                            
                            <button className="mb-3">
                            <a href="#inq" class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold shadow text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
            <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
            <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
              <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
              </svg>
            </span>
            <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
              <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
              </svg>
            </span>
            <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
inquiry</span>
          </a>
                               </button>
                            
                          </div>
                        );
                      })}
                  </div>
                 
                </div>
             
    </>
  );
}

export default Cardproduct;
