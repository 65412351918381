import React,{useState} from 'react'
import Cardnavbar from './Cardnavbar'
import Cardpayment from './Cardpayment'
import Cardhome from './Cardhome'
import Cardproduct from './Cardproduct'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import "./Cardproduct.css"

const Card = () => {
  //  let url="http://192.168.1.12:8000/card"
  let url="https://api.srwater.in/card"

  let {names}=useParams()
  const [detail,setDetail]=useState({})

  const getData=async()=>{
    
    try {
      let data=await fetch(`${url}/${names}`)
      data=await data.json()
      // console.log(data.data[0]);
      setDetail(data.data[0])
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getData()
    // console.log(detail);
  },[])
  return (
    <>
    <div className='fond  rgba'>
       
       <Cardhome detail={detail} />
       {/* <Cardproduct detail={detail}/> */}
       {/* <Cardpayment detail={detail}/> */}
       {/* <Cardnavbar detail={detail}/> */}
       </div> 
    </>
  )
}

export default Card
