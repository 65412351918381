import React from 'react'
import schemeimage from "./images/schemeimage.png";
import AnnulTokenNumber from "./AnnulTokenNumber";
import "./AnnualScheme.css";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    extendTheme,
    useToast,
  } from '@chakra-ui/react'
import { useState } from 'react';
const AnulPopUp = ({name,password}) => {
    
        const { isOpen, onOpen, onClose } = useDisclosure()
        const [data,setData]=useState({
          name:"",
          challan_no:"",
          amount:"",
          mobile:"",
        })

        const [size, setSize] = React.useState('md')
        const toast=useToast()
        const handleClick = async() => {
          // setSize(newSize)
          if(name==="" && password==""){
            
           toast({
              title: "Please Enter Fields",
              position:"top",
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
          }
          try {
            let res=await fetch(`https://api.srwater.in/annual/login`,{
              method:"POST",
              body:JSON.stringify({name,password}),
              headers:{
                "Content-Type": "application/json"
              }
            })
            res=await res.json()
            console.log(res);
            setData({...data,userid:res.data._id})
            if(res.msg=="You Have been successfully Logged In"){
              toast({
                title: res.msg,
                position:"top",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })
              onOpen()
            }
            else{
              toast({
                title: res.msg,
                position:"top",
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
            }
          } catch (error) {
            toast({
              title: "Please Check Entered Fields",
              position:"top",
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
          }
          

        }
      
        const sizes = [ 'full']
        const theme = extendTheme({
            components: {
              Modal: {
                baseStyle: (props) => ({
                  dialog: {
                    maxWidth: ["80%", "80%", "80%"],
                    minHeight: ["70%", "70%", "70%"],
                    bg: "lightgray",
                    boxShadow: "none",
                  },
                }),
              },
            },
          });
  return (

    <>
      {/* {sizes.map((size) => (
        <Button
          onClick={() => handleSizeClick(size)}
          key={size}
          m={4}
        >{`Open ${size} Modal`}</Button>
      ))} */}
  <Button onClick={handleClick} _hover={{borderColor:"#151A27",color:"#151A27",bgColor:"white"}} bgColor={"#151A27"} color="white" >Log In </Button>
<Modal
        
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        theme={theme}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
<ModalBody className="modals">
<div class=" annnul">
          <div class="contact-box">
            <div class="right">
              
              <input type="partyname" required value={data.name} onChange={(e)=>setData({...data,name:e.target.value})} className=" my-2 username" placeholder="Party Name" />
              
              <input type="chalanumber" required value={data.challan_no} onChange={(e)=>{
                setData({...data,challan_no:e.target.value.replace(/\s/g,"")})}} className=" my-2 password" placeholder="Challan" />
              
              <input type="amount" required value={data.amount} onChange={(e)=>setData({...data,amount:e.target.value})}  className=" my-2 password" placeholder="Amount" />
            
              <input type="mobilenumber" required  value={data.mobile} onChange={(e)=>setData({...data,mobile:e.target.value.replace(/\s/g,"")})}  className=" my-2 password" placeholder="Mobile Number" />
              
              <div className="my-2 d-flex" >
              {/* <button className="butt">  <i class="fa-regular fa-hand-point-up fa-xl mx-2"></i>Submit<AnnulTokenNumber/> </button> */}
              <AnnulTokenNumber data={data}/>
              </div>
            </div>
            <div class="left">
              <img src={schemeimage} alt="" />
            </div>
          </div>
        </div>
          </ModalBody>
         
        </ModalContent>
      </Modal>
    </>
 
  
  )
}


export default AnulPopUp
