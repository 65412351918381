import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const NotFoundPage = () => {
  return (
    <div>
      <Box  textAlign={"center"}> 
        <Text fontWeight={"extrabold"} fontSize={"50px"}>404</Text>
        <Text fontWeight={"bold"} fontSize={"30px"}>THis Page Does Not Exist</Text>
      </Box>
    </div>
  )
}

export default NotFoundPage
