import React, { useState } from "react";

import srlogo from "./images/srlogo.png";
import schemeimage from "./images/schemeimage.png";
import "./AnnualScheme.css";
import AnulPopUp from "./AnulPopUp";
import { useEffect } from "react";
import navContext from "../context/navDetail";
import { useContext } from "react";
const AnnualScheme = () => {
  const [name,setName]=useState("")
  const [password,setPassword]=useState("")
  const {schemeData}=useContext(navContext)


  useEffect(()=>{
  },[])
  
  return (
    <div className="fond">
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light bg-white"
        style={{ width: "100%" }}
      >
        <div
          className="container-fluid"
          style={{ justifyContent: "space-around" }}
        >
          <a className="navbar-brand fw-bold" href="/">
            <img style={{ width: "100px" }} src={srlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-3">
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-3 ">
                <a className="nav-link " href="/About">
                  About Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Product">
                  Products
                </a>
              </li>
              {/* <li className="nav-item mx-3">
                <a className="nav-link" href="/Blogs">
                  Blogs
                </a>
              </li> */}
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Newsevent">
                  News & Events
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/Ebroucher">
                  E-Brochure
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  style={{ fontWeight: "bolder" }}
                  className="nav-link changingcolour"
                  href="/AnnualScheme"
                >
                 {schemeData}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <section>
          <div className="container">
            <div className="" style={{alignItems:"center", border:"1px solid black"}}>
       
              <div>
              <form action="">
                <input type="text" placeholder='hiii' />
                <br />
                <input type="text" placeholder='hiii'/>
              </form>
              </div>
              <div className="imagesss">
                <img style={{width:"464px", height:"569px"}} src={schemeimage} alt="" />
              </div>
            </div>
        
          </div>
         </section> */}
      <section>
        <div class=" annnul">
          <div class="contact-box">
            <div class="right">
              <div className="d-flex" style={{alignItems:"center"}}>
            <i class="fa-solid fa-user fa-xl"></i>
              <input type="username" value={name} onChange={(e)=>setName(e.target.value)}  className=" my-2 username" placeholder="User Id" />
              </div>
              <div className="d-flex" style={{alignItems:"center"}}>
              <i class="fa-solid fa-lock fa-xl"></i>
              <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className=" my-2 password" placeholder="Password" />
              </div>
              <div className="my-2">
              <AnulPopUp name={name} password={password}/> 
              {/* className="butt" */}
              </div>
            </div>
            <div class="left">
              <img src={schemeimage} alt="" />
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
};

export default AnnualScheme;
